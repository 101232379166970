<template>
  <van-grid column-num="5" class="advBox" :border="false" v-if="showAdv">
    <van-grid-item
      @click.stop="jumpAdv(item, 'ads')"
      v-for="(item, index) in advRowList"
      :key="index"
    >
      <template #icon>
        <LazyImg class="rectAdvIcon" :src="item.cover" />
      </template>
      <template #text>
        <span class="title ellipsis">{{ item.title }}</span>
      </template>
    </van-grid-item>
  </van-grid>
</template>
<script>
import { getAdItem, AdType, jumpAdv } from "@/utils/getConfig";
import { Grid, GridItem } from "vant";
import LazyImg from "@/components/LazyImg";
export default {
  name: "ColumnAdvertising",
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    LazyImg,
  },
  computed: {
    showAdv() {
      if (typeof this.index !== "number") return false;
      return (this.index + 1) % 2 == 0;
    },
    advRowList() {
      let advRowList = getAdItem(AdType.COLUMNADVERTISING);
      if (!this.showAdv || !advRowList || !advRowList.length) return [];
      let start = ((this.index - 1) / 2) * 5;
      let end = start + 5;
      while (advRowList.length < end) {
        advRowList = advRowList.concat(advRowList);
      }
      return advRowList.slice(start, end);
    },
  },
  methods: {
    jumpAdv,
  },
};
</script>
<style lang="scss" scoped>
.advBox {
  --van-grid-item-content-background: transparent !important;
  --van-grid-item-icon-size: 60px !important;
  --van-grid-item-content-padding: 0 !important;
  width: 100%;
  margin: 3px 0;
  padding: 0 !important;
  grid-gap: 0 !important;
  /deep/ .van-grid-item__content {
    padding: 2px 5px;
  }
  .rectAdvIcon {
    width: 60px;
    height: 60px;
    border-radius: 14px;
    overflow: hidden;
  }
  .title {
    font-size: 12px;
    margin-top: 8px;
    width: 60px;
  }
}
</style>
